

    import { defineComponent } from 'vue'
    import Disney from '../components/Logos/Disney.vue'
    import Hublot from '../components/Logos/Hublot.vue'
    import Porsche from '../components/Logos/Porsche.vue'
    import Epson from '../components/Logos/Epson.vue'
    import Ikea from '../components/Logos/Ikea.vue'
    import LVMH from '../components/Logos/LVMH.vue'
    import PWC from '../components/Logos/PWC.vue'
    import Vice from '../components/Logos/Vice.vue'
    import Microsoft from '../components/Logos/Microsoft.vue'
    import Nike from '../components/Logos/Nike.vue'
    import UBS from '../components/Logos/UBS.vue'
    import EF from '../components/Logos/EF.vue'
    import Agoda from '../components/Logos/Agoda.vue'
    import Lenovo from '../components/Logos/Lenovo.vue'
    import Kaspersky from '../components/Logos/Kaspersky.vue'
    import { usePartnerLogoAnimation } from '../compositions/UsePartnerLogoAnimation'
    import { VueInstance } from '../Interfaces/VueInstance'
    import { useResponsiveValue } from '../compositions/UseResponsiveValue'

    export default defineComponent({
        name: 'Partners',
        setup() {

            const logos: VueInstance[] = [
                Microsoft,
                Hublot,
                Nike,
                Ikea,
                UBS,
                Disney,

                /**
                 * Other logos
                 */
                Kaspersky,
                Vice,
                Epson,
                Agoda,
                Porsche,
                Lenovo,
                PWC,
                LVMH,
                EF
            ]

            const numberOfVisibleLogos = useResponsiveValue([ 3, 6 ])
            const { activeLogos, hooks } = usePartnerLogoAnimation(logos, numberOfVisibleLogos)

            return {
                activeLogos,
                hooks
            }

        }
    })

