import { onMounted, onUnmounted, ref, markRaw, Ref, watch } from 'vue'
import { VueInstance } from '../Interfaces/VueInstance'
import shuffle from 'lodash.shuffle'

export type LogoReference = {
    id: string,
    component: VueInstance,
}

function generateRandomID(length = 6): string {

    return Math.random().toString(36).substr(2, length)

}

function randomIntFromInterval(min: number, max: number) {

    return Math.floor(Math.random() * (max - min + 1) + min)

}

function transitionElement(logo: SVGElement, onEnd: () => void, removeClass: string, addClass: string) {

    const groups = logo.querySelectorAll('g')

    let delay = 0

    for (const group of shuffle(groups)) {

        const value = randomIntFromInterval(50, 100)

        setTimeout(() => {

            group.classList.remove(removeClass)
            group.classList.add(addClass)

        }, delay += value)

    }

    setTimeout(onEnd, delay + 667) // 667 = transition duration of each logo

}

let lastRandomIndex: number[] = []

function randomIndex(min: number, max: number, length: number): number[] {

    const numbers = []

    for (let count = min; count <= max; count++) {

        numbers.push(count)

    }

    const randomized = shuffle(numbers).slice(0, length)

    /**
     * If any value within is duplicated.. regenerate
     */
    if (randomized.some(number => lastRandomIndex.includes(number))) {

        return randomIndex(min, max, length)

    }

    return (lastRandomIndex = randomized)

}

function rotate(logos: LogoReference[]): LogoReference[] {

    logos.unshift(logos.pop()!)

    return logos

}

export function usePartnerLogoAnimation(logos: VueInstance[], maxNumberOfVisibleLogo: Ref<number>, simultaneouslySwitchLimit = 1) {

    const switchLogoInterval = 2000
    const overlapDelay = 500

    const allLogos: LogoReference[] = logos.map(logo => ({
        id: generateRandomID(),
        component: markRaw(logo)
    }))

    const activeLogos = ref<LogoReference[]>(allLogos.slice(0, maxNumberOfVisibleLogo.value))

    function findReplacementLogo(simultaneouslySwitchLimit: number): LogoReference[] {

        return rotate(allLogos)

            /**
             * Remove all logos that is already in use
             */
            .filter(element => !activeLogos.value.find(component => component.id === element.id))

            /**
             * Only take a fixed amount of logos
             */
            .slice(0, simultaneouslySwitchLimit)

    }

    const unwatchResponsiveLogoNumber = watch(maxNumberOfVisibleLogo, (newValue, oldValue) => {

        if (newValue > oldValue) {

            const appendLogoNumber = newValue - oldValue
            const appendLogos = findReplacementLogo(appendLogoNumber)

            activeLogos.value.push(...appendLogos)

        } else {

            const popLogoNumber = oldValue - newValue

            activeLogos.value.splice(activeLogos.value.length - popLogoNumber, popLogoNumber)

        }

    })

    function switchLogos() {

        const replacementLogos = findReplacementLogo(simultaneouslySwitchLimit)

        let replacementIndex = 0

        for (const index of randomIndex(0, activeLogos.value.length - 1, simultaneouslySwitchLimit)) {

            const replacement = replacementLogos[replacementIndex++]

            activeLogos.value[index] = {
                component: replacement.component,
                id: replacement.id
            }

        }

    }

    let switchInterval: number

    onMounted(() => {

        setTimeout(() => (switchInterval = window.setInterval(switchLogos, switchLogoInterval)), 2000)

    })

    onUnmounted(() => {

        unwatchResponsiveLogoNumber()

        if (switchInterval) {

            clearInterval(switchInterval)

        }

    })

    return {
        activeLogos,
        hooks: {
            enter(element: SVGElement, done: () => void) {

                setTimeout(() => transitionElement(element, done, 'hide', 'show'), overlapDelay)

            },
            leave(element: SVGElement, done: () => void) {

                transitionElement(element, done, 'show', 'hide')

            }
        }
    }

}
