import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, Transition as _Transition, toHandlers as _toHandlers, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  class: "partners w-full flex justify-around md:justify-between items-center relative",
  style: {"color":"#666666"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeLogos, (logo, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "relative flex items-center justify-center"
      }, [
        _createVNode(_Transition, _mergeProps({
          name: "logo",
          appear: ""
        }, _toHandlers(_ctx.hooks)), {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(logo.component), {
              key: logo.id
            }))
          ]),
          _: 2
        }, 1040)
      ]))
    }), 128))
  ]))
}